import Swiper from "swiper";
import { Pagination } from "swiper/modules";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  function handleDOMContentLoaded() {
    new Swiper('.articles', {
      modules: [ Pagination ],
      slidesPerView: 'auto',
      spaceBetween: 30,
      slideClass: 'article-teaser',
      freeMode: true,
      grabCursor: true,
      pagination: {
        el: '.articles__pagination',
        type: 'bullets',
        bulletClass: 'articles__bullet',
        bulletActiveClass: 'articles__bullet--active',
        clickable: true
      }
    });

    new Swiper('.logos', {
      slidesPerView: 'auto',
      spaceBetween: 30,
      freeMode: true,
      grabCursor: true
    });

    new Swiper('.testimonials__list', {
      modules: [ Pagination ],
      slidesPerView: 1,
      spaceBetween: 9,
      freeMode: true,
      grabCursor: true,
      pagination: {
        el: '.testimonials__pagination',
        type: 'bullets',
        bulletClass: 'articles__bullet',
        bulletActiveClass: 'articles__bullet--active',
        clickable: true
      },
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      }
    });
  }
})();
